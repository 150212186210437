<template>
  <div class="payplan-dialog">
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">{{ title }}</div>
      <div class="erp-form-bar">
        <el-button class="erp-primary__btn" size="small" type="primary" @click="submit(formType)">保存</el-button>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="erp-form__body">
      <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
        <div class="mali-edit__form">
          <el-row :gutter="16">
            <el-col :span="12">
              <el-form-item label="预计付款日期" prop="expectPayDate">
                <el-date-picker
                  v-model="formData.expectPayDate"
                  type="date"
                  format="YYYY-MM-DD"
                  placeholder="请选择预计付款日期"
                  prefix-icon=" "
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="16">
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input
                  :maxlength="100"
                  v-model="formData.remark"
                  clearable
                  placeholder="请输入备注"
                  type="textarea"
                  class="mali-full__inputcom h88"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { httpPost } from '@/api';
import { formatTime } from '@/utils/publicMethods';
export default {
  props: {
    formType: {
      // 表单操作类型 // formType 3 查看 31 查看   4提交申请 5重新提交  6取消  7审核   8录入信息
      type: [String, Number],
      default: '',
    },
    title: {
      type: [String],
      default: '',
    },
    rowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      formData: {
        remark: this.rowData.paymentDesc ? this.rowData.paymentDesc : '',
        expectPayDate: this.rowData.expectPayDate ? this.rowData.expectPayDate : '',
      },
      rules: {
        expectPayDate: [{ required: true, message: ' ', trigger: ['blur', 'change'] }],
      },
      isAjaxLoading: false,
    };
  },
  methods: {
    back() {
      this.$emit('cancel', true);
    },
    /**
     * 提交事件
     */
    submit() {
      // 确认提交
      if (!this.formData.expectPayDate) {
        this.errorTip('请将必填项填写完整');
      } else {
        this.saveSubmitForm();
      }
    },
    async saveSubmitForm() {
      let params = {};
      const { remark, expectPayDate } = this.formData;
      const { version, id, applyType, applyPayAmount } = this.rowData;
      params = { version, id, applyType, remark, expectPayDate };
      params.applyPayAmount = applyPayAmount;
      params.expectPayDate = formatTime(params.expectPayDate, 'YYYY-MM-DD');
      if (this.isAjaxLoading) {
        return;
      }
      this.isAjaxLoading = true;
      const res = await httpPost('/malicrm/apply/pay/entryPaymentInfo', params);
      this.isAjaxLoading = false;
      if (res.code === 200) {
        this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
      } else {
        this.errorTip(res.message);
      }
    },
  },
};
</script>
