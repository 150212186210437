import { ErpTableOptions } from '@/types/type';

import { computed, defineComponent, ref } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();
  const status = [
    {
      value: false,
      label: '否',
    },
    {
      value: true,
      label: '是',
    },
  ];
  const applyTypeOption = [
    {
      value: 1,
      label: '预付款',
    },
    {
      value: 2,
      label: '尾款',
    },
  ];
  const columnList: ErpTableOptions[] = [
    {
      label: '申请编号',
      prop: 'applyNo', // 属性
      minWidth: 131,
      value: '',
      type: 'input',
    },
    {
      label: '销售合同号',
      prop: 'businessContractNo', // 属性
      minWidth: 143,
      value: '',
      type: 'input',
    },
    {
      label: '是否代采',
      prop: 'isNeedCg',
      propDesc: 'isNeedCgDesc',
      minWidth: 130,
      value: '',
      type: 'signSelect',
      options: status,
    },
    {
      label: '供应商合同号',
      prop: 'supplierContractNo',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '供应商',
      prop: 'supplierName',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 183,
      value: '',
      type: 'input',
    },
    {
      label: '抬头',
      prop: 'consigneeShortName',
      minWidth: 103,
      value: '',
      type: 'input',
    },
    {
      label: '申请付款类型',
      prop: 'applyType',
      propDesc: 'applyTypeDesc',
      type: 'signSelect',
      options: applyTypeOption,
      value: '',
      minWidth: 154,
    },
    {
      label: '申请付款金额',
      prop: 'applyPayAmount',
      propDesc: 'applyPayAmountDesc',
      value: [],
      type: 'number',
      filterProp: ['applyPayAmountMin', 'applyPayAmountMax'],
      minWidth: 154,
    },
    {
      label: '交易币种',
      prop: 'tradeCurrency',
      propDesc: 'tradeCurrencyDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',
      options: 'trade_currency',
    },
    {
      label: '申请人',
      prop: 'applyOperator',
      minWidth: 128,
      value: '',
      type: 'input',
    },
    {
      label: '申请时间',
      prop: 'applyTime',
      minWidth: 174,
      value: [],
      type: 'time',
      filterProp: ['applyTimeStartDate', 'applyTimeEndDate'],
    },
    {
      label: '审核状态',
      prop: 'auditStatus',
      propDesc: 'auditStatusDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',
      options: 'order_payment_notice_audit_status',
    },
    {
      label: '预计付款日期',
      prop: 'expectPayDate',
      minWidth: 154,
      type: 'time',
      filterProp: ['expectPayDateStartDate', 'expectPayDateEndDate'],
      value: [],
    },
    {
      label: '实际付款日期',
      prop: 'actualPayDate',
      minWidth: 154,
      type: 'time',
      filterProp: ['actualPayDateStartDate', 'actualPayDateEndDate'],
      value: [],
    },
    {
      label: '实际付款金额',
      prop: 'actualPayAmount',
      propDesc: 'actualPayAmountDesc',
      minWidth: 154,
      type: 'number',
      filterProp: ['actualPayAmountMin', 'actualPayAmountMax'],
      value: [],
    },
    {
      label: '审核人',
      prop: 'auditOperator',
      minWidth: 120,
      value: '',
      type: 'input',
    },
    {
      label: '审核时间',
      prop: 'auditTime',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['auditTimeStartDate', 'auditTimeEndDate'],
    },
    {
      label: '单证对接人',
      prop: 'documentAccess',
      minWidth: 146,
      value: '',
      type: 'input',
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 188,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button  v-if="isShowAuditBtn && hasPermission('erp:fm:auditPaymentRecord')"  type="primary" plain @click="operateFn('审核',row)">
        审核</el-button>
        <el-button  v-if="isShowInputInfoBtn && hasPermission('erp:fm:auditPaymentRecord')" type="primary" plain @click="operateFn('录入信息',row)">
        录入信息
        </el-button>
        <el-button  v-if="isShowCancelBtn && hasPermission('erp:fm:auditPaymentRecord')"  plain @click="operateFn('取消',row)">
        取消</el-button>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup(props) {
          const operateFn = (status, row) => {
            Instance.setupState.operateFn(status, row);
          };
          // 1.审核状态为“审核不通过”或“已取消” auditStatusDesc
          // 2.申请状态为“已取消” applyStatusDesc
          // 1.申请状态为“未申请”
          // 2.已收到保证金（即“收到保证金日期”不为空）
          // 3.PI/SC/都显示“已上传”，标签文件显示“标签齐全”
          // 申请状态为“已申请”且审核状态为“待审核”
          // auditStatus {"1":"待审核","2":"审核不通过","3":"审核通过","4":"已取消"}
          // applyStatus {"0":"未申请","1":"已申请","2":"已取消"}
          const isShowAuditBtn = computed(() => {
            const { auditStatus } = props.row;
            return auditStatus === 1;
          });
          const isShowInputInfoBtn = computed(() => {
            const { auditStatus, actualPayDate, actualPayAmount } = props.row;
            return (
              auditStatus === 3 && !actualPayDate && actualPayAmount === null
            );
          });
          const isShowCancelBtn = computed(() => {
            const { auditStatus, actualPayDate, actualPayAmount } = props.row;
            return (
              auditStatus === 3 && !actualPayDate && actualPayAmount === null
            );
          });
          return {
            isShowAuditBtn,
            isShowInputInfoBtn,
            isShowCancelBtn,
            operateFn,
          };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
